import React from "react";
import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
// import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

function CardView({ title, NumberOfUsers }) {
  return (
    <Card sx={{ minWidth: 275 }} className="centerContent">
      <CardContent>
        <Typography
          sx={{ textTransform: "uppercase", fontSize: 20 }}
          variant="h2"
          //   sx={{  }}
        >
          {title}
        </Typography>
        <Typography sx={{ fontSize: 20 }}>{NumberOfUsers}</Typography>
        <Typography variant="h5" component="div"></Typography>
      </CardContent>
    </Card>
  );
}

export default CardView;
