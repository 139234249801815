import { useState, useEffect } from "react";
import axios from "axios";
import "./PrivateScreen.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  CardActionArea,
  CardMedia,
  Grid,
  Card,
} from "@mui/material";
import TableOfContent from "./table";
import CardView from "./Card";
import EmpTable from "./EmpTable";
import Logo from "../../assets/logo.png";
const PrivateScreen = ({ history }) => {
  const [error, setError] = useState("");
  const [privateData, setPrivateData] = useState("");
  const [usersData, setUsersData] = useState("");

  useEffect(() => {
    const fetchPrivateDate = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };

      try {
        const { data } = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/admin/users/data",
          config
        );
        setPrivateData(data.employerData.employers);
        setUsersData(data.userData.users);

        if (data) {
          // console.log(data.employerData.employers, "priv");
        }
      } catch (error) {
        // localStorage.removeItem("authToken");
        // setError("You are not authorized please login", error);
        console.log(error);
      }
    };
    //  :

    fetchPrivateDate();
  }, []);
  const logoutHandler = (e) => {
    e.preventDefault();
    localStorage.removeItem("authToken");
    history.push("/login");
  };
  return error ? (
    <span className="error-message">{error}</span>
  ) : (
    <>
      <Box sx={{ flexGrow: 1 }} mt={4} m={2} className="center" xs={12}>
        <Grid container spacing={2} columns={16}>
          <Grid item xs={16} md={16} className="nav">
            <span></span>
            <Card sx={{ maxWidth: "20%" }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="40"
                  width="40"
                  image={Logo}
                  alt="logo"
                  className="logo"
                />
              </CardActionArea>
            </Card>
            <span></span>
          </Grid>
          <Grid item xs={16} md={8}>
            {privateData && (
              <>
                <CardView
                  title="Total Of Employers"
                  NumberOfUsers={privateData.length}
                />
                <br />
                {/* {console.log(privateData, " console.log(error);")} */}
                <EmpTable usersData={privateData} />
              </>
            )}
          </Grid>
          <Grid item xs={16} md={8}>
            {" "}
            {usersData && (
              <>
                <CardView
                  title="Total Of Jobseekers"
                  NumberOfUsers={usersData.length}
                />
                <br />
                <TableOfContent usersData={usersData} />
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <Button variant="error" onClick={logoutHandler}>
        Logout
      </Button>

      <br />

      {/* {privateData && usersData && <TableOfContent usersData={usersData} />} */}
    </>
  );
};

export default PrivateScreen;
